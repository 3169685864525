@import "./font.scss";
@import "./icon.scss";

* {
  box-sizing: border-box !important;
}

.col {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: column;
}

.row {
  display: -moz-flex;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.row-reverse {
  flex-direction: row-reverse;
}

.v-ctr {
  align-items: center;
}

.h-ctr {
  justify-content: center;
}

.v-start {
  align-items: flex-start;
}

.v-end {
  align-items: flex-end;
}

.h-btn {
  justify-content: space-between;
}
.v-btn {
  align-items: space-between;
}

.h-arnd {
  justify-content: space-around;
}

.h-end {
  justify-content: flex-end;
}

.flex-full,
.f-rest {
  flex: 1;
}

.fwarp {
  flex-wrap: wrap;
}

.f-rest-2 {
  flex: 2;
}

.o-hide {
  overflow: hidden;
}

.oy-auto {
  overflow: hidden auto;
}

.btn {
  outline: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
}

body {
  width: 100vw;
  height: 100vh;
  margin: 0;
}

.ant-app {
  font-size: inherit;
  font-family: 'Roboto';
  color: inherit;
}

.w-100 {
  width: 100%;
}
.w-49 {
  width: 49%;
}
.h-50{
  height: 48%;
}
.h-100 {
  height: 100%;
}

.cFFF {
  color: #FFF;
}

.cFE3333 {
  color: #FE3333;
}
.cc0000{
  color: #cc0000;
}
.c0133CC {
  color: #0133CC;
}

.c777777 {
  color: #777777;
}

.c1155CC {
  color: #1155CC;
}

.c00085 {
  color: rgba(0, 0, 0, 0.85);
}

.c00045 {
  color: rgba(0, 0, 0, 0.45);
}

.c00025 {
  color: rgba(0, 0, 0, 0.25);
}

.c000 {
  color: #000;
}
.c999{
  color: #999999;
}
.cBFBFBF {
  color: #BFBFBF;
}
.c1890FF{
  color: #1890FF;
}

.bgOverall {
  background-color: #1E686D;
}

.c1E686D {
  color: #1E686D;
}

.bg-primary {
  background-color: #0133CC;
}

.bg59A7AC {
  background-color: #59A7AC;
}

.bgWhite {
  background-color: #FFF;
}

.bgOutline,
.bgDraft {
  background-color: #871211;
}

.bgReview {
  background-color: #FE7733;
}
.bgapproved,
.bgApproved {
  background-color: #FA8C16;
}

.bgPublished,
.bgActive {
  background-color: #33CC77;
}

.bgInactive,
.bgconfidential,
.bgConfidential {
  background-color: #871211;
}

.bgpersonal,
.bgPersonal {
  background-color: #871211;
}

.bgrestricted,
.bgRestricted {
  background-color: #FE7733;
}

.bginternal,
.bgInternal {
  background-color: #FEFE77;
}

.bgpublic,
.bgPublic {
  background-color: #33CC77;
}

.bgCFE3333 {
  background-color: #FE3333;
}

.bgarchived,
.bgArchived {
  background-color: #ADADAD;
}
.bgDDFFFF{
  background-color: #DDFFFF;
}
.btn-menu {
  border: 1px solid #D9D9D9;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
  border-radius: 2px;
  height: 32px;
  width: 32px;
}
.bgFFDDDD{
  background-color: #FFDDDD;
}
.bg238787{
  background-color: #238787;
}
.bg882222{
  background-color: #882222;
}
.bgpending,
.bgPending{
  background-color: #FF0200;
}
.bgstarted,
.bgStarted{
  background-color: #FE7733;
}
.bgcompleted,
.bgpassed,
.bgCompleted{
  background-color: #00FF19;
}
.c882222{
  color: #882222;
}
.c777 {
  color: #777;
}
.cinherit {
  color: inherit;
}

.c0033CC {
  color: #0033CC;
}
.c238787{
  color: #238787;
}
.cDDD{
  color: #DDD;
}
.capital,
.caps {
  text-transform: capitalize;
}

.tup {
  text-transform: uppercase;
}
.txt-left{
  text-align: left;
}
.text-center {
  text-align: center;
}

.screen {
  position: relative;
}

.progress-cnt {
  position: absolute;
}

.api-progress {
  position: relative !important;
  visibility: hidden;

  &.show {
    visibility: visible;
  }
}

.screen-pad {
  padding: 1.6rem 2.4rem;
}

.doc-nav {
  background: #DDFFFF;
  width: 4.6rem;
  padding-top: 10.2rem;
  transition: width 0.4s ease-in-out;

  &:hover {
    width: 14rem;
  }

  .item {
    width: 100%;
    height: 4.8rem;
    margin-bottom: 8px;
    padding-left: 1.4rem;
    background-color: transparent;

    .icon {
      margin-right: 1.6rem;
    }

    &.active {
      background-color: #173436;
    }
  }
}

.txt-justify {
  text-align: justify;
}

.ck-container {
  p {
    margin: 0;
  }

  .title {
    margin-bottom: 0.8rem;
  }

  * {
    box-sizing: unset !important;
  }
}

p.ck-container {
  margin: 0;
  font-size: unset;
  font-family: unset;
  &.max{
    max-height: 180px;
  }
}

.no-access {
  padding: 8rem;

  .btn {
    align-self: unset;
    margin-top: 3rem;
  }

  .title {
    font-size: 10rem;
    font-style: italic;
    color: #238787;
  }

  .text-sec {
    width: 60%;
    padding-top: 11rem;
  }

  .img-sec {
    width: 40%;
  }

  .eval {
    max-width: 400px;
    max-height: 600px;
  }
}

.ant-table-filter-column,
.ant-table-column-sorters {
  font: inherit !important;
  color: inherit !important;

  .ant-table-column-title {
    font: inherit !important;
    color: inherit !important;
  }
}

.ant-table-filter-column {
  .ant-table-filter-trigger {
    margin-right: 8px;
  }
}

.ant-table-thead .text-center {
  text-align: center !important;
}

.ant-table-body {
  .ant-table-cell {
    vertical-align: top;
  }

  .btn {
    padding: 0;
  }
}

.ant-table-cell {
  padding: 1rem 16px !important;
}

.ant-dropdown {
  z-index: 1300;
}
.cke_toolbar {
  * {
    box-sizing: unset !important;
    font-family: unset;
  }
  p:first-child{
    // display: none;
  }
  &.tbl_of_content{
      p {
        width: 100%;
        clear: both;
        span:nth-child(2){
          float: right;
        }
      }
  }
}
.cke_editable{
  padding: 0 4px;
}
.new-content {
  .header {
    padding-bottom: 1.6rem;

    .title-input {
      margin: 0;
      width: 60rem;

      .label-cont {
        display: none;
      }

      .container {
        padding-left: 0;
      }
    }

    .btn:nth-child(2) {
      margin: 0 1.6rem;
    }
  }
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}
.ant-table-placeholder .ant-table-cell{
  border-bottom: none;
}
.no-scroll-bar {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.no-scroll-bar::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}