.eval-assess {
  .title-sec{
    padding: 16px;
    box-shadow: 0px -1px 0px 0px #F0F0F0 inset;
    .title-row{
      padding-left: 8px;
      margin-bottom: 24px;
    }
  }
  .assess-question{
    padding: 16px;
    .stem{
      margin: 4px 0 12px 8px;
    }
    .option{
      margin-bottom: 6px;
    }
  }
  .dialog-actions{
    display: none;
  }
}