.dash-table {
  border-radius: 10px;
  background: rgba(217, 217, 217, 0.30);
  padding: 16px;
  width: 60%;
  min-height: 48%;
  &:first-child{
    margin-bottom: 16px;
  }
  .title-row{
    margin-bottom: 4px;
  }
  .title{
    margin-left: 12px;
  }
  .btn-export{
    .label{
      margin-right: 0.8rem;
    }
    .icon-text{
      margin-right: 0;
    }
  }
  .table-sec {
    flex: 2;
  }
}
.circular-preview {
  width: 432px;
  padding: 0;
  .dialog-actions{
    padding: 0;
  }
}
.menus .item{
  min-width: 65px;
}